/*
*/
.App {
  min-height: 100vh;
}

.main {
  min-height: 90vh;
}

.dpInput {
  border-radius: 0.4em;
  border-color: #5a5a5a;
  width: 100%;
}

.footer {
  background-color: #282c34;
  margin-top: auto;
  margin-left: 0%;
  margin-right: 0%;
  width: 100%;
  height: 5vh;
  bottom: 0;
  color: white;
  /*  font-size: 25px; */
}

.copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.tbnavbar {
  margin-bottom: 0px;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  /*
  background-color: #282c34;
  */
  background-color: white;
  height: 68px;
  /*
  height: 5vh;
  */
}

.navbar {
  margin: 0px;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  /* box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.3); */
  background-color: #282c34;
  /* background-image: url("../public/navbar-bg.jpg"); */
}

.smfont {
  font-size: x-small;
}

.xsmfont {
  font-size: xx-small;
}

body {
  /* padding-top: 3rem;
  padding-bottom: 3rem;
   */
  color: #5a5a5a;
  font-family: Arial, Helvetica, sans-serif;
}

html,
#root {
  height: 100%;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

a:link,
a:visited {
  text-decoration: none;
}

/* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 26rem;
}

/* MARKETING CONTENT
  -------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

/* Featurettes
  ------------------------- */

.sm-featurette-divider {
  margin: 2rem 0; /* Space out the Bootstrap <hr> more */
}

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading {
  letter-spacing: -0.05rem;
}

/* RESPONSIVE CSS
  -------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.quicklinks {
  margin: 0;
  padding: 0;
}

.logo {
  /*  width: 220px; */
  min-height: 51px;
  font-size: 12px;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  max-width: 450px !important;
  background-color: #f7f7f7;
  margin: 0 auto 25px;
  margin-top: 0px;
  padding: 40px 40px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.report-tile {
  max-width: 850px !important;
  background-color: #fdfdfd;
  margin: 0 auto 25px;
  margin-top: 0px;
  padding: 20px 20px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.servicesolv-title {
  color: #266a7d;
}

.ss-featurette-heading {
  font-size: 50px;
}

.brown-card {
  color: white;
  background-color: dimgray;
  padding: 40px 40px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.servicesolv-card {
  max-width: 350px !important;
  color: white;
  background-color: #266a7d;
  border-color: #282c34;
  margin: 0 auto 25px;
  margin-top: 0px;
  padding: 40px 40px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: -2;
}

.form-signin input {
  margin-bottom: 5px;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
